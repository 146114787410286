





















import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiClose } from "@mdi/js";

@Component
export default class SnackAlert extends Vue {
  @Prop({ required: true, type: String }) href!: string;
  snackbarShow = true;
  closeIcon: string = mdiClose;

  onClose(): void {
    this.snackbarShow = false;
  }
}
