






















































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import "firebaseui/dist/firebaseui.css";
import { mdiCheckCircle, mdiGithub, mdiLogin, mdiTwitter } from "@mdi/js";
import { Action, Getter } from "vuex-class";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Typewriter from "typewriter-effect/dist/core";
import SnackAlert from "@/components/SnackAlert.vue";
import { AppData } from "@/types/state";
import { isAndroid, isInStandaloneMode } from "@/plugins/utils";
@Component({
  components: { SnackAlert },
})
export default class Home extends Vue {
  loginIcon: string = mdiLogin;
  githubIcon: string = mdiGithub;
  twitterIcon: string = mdiTwitter;
  tickIcon: string = mdiCheckCircle;

  @Getter("appData") appData!: AppData;
  @Action("setTitle") setTitle!: (title: string) => void;

  get githubLInk(): string {
    return process.env.VUE_APP_GITHUB_LINK as string;
  }

  get canDownloadApp(): boolean {
    return isAndroid() && !isInStandaloneMode();
  }

  mounted(): void {
    this.setTitle("");
    new Typewriter("#typewriter", {
      strings: ["Grocery", "Weekly", "Christmas", "Ultimate"],
      autoStart: true,
      loop: true,
    });
  }
}
